import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "logo.png",
        "alt": "ロゴ"
      }}></img></p>
    <p>{`京阪マーケティング・リサーチ機構は、マーケティング及び関連領域に関する研究・普及活動を行う一般社団法人です。`}</p>
    <p>{`当法人では、マーケティング・リサーチの研究活動・普及活動だけでなく、人材育成や周辺領域への適用を実施しています。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      