import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`News`}</h2>
    <ProjectCard title="代表社員桐野が石油学会誌にて査読付き学術論文を発表しました。" link="https://www.jstage.jst.go.jp/article/jpi/66/4/66_109/_article/-char/ja" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  当機構代表社員桐野が、2023/7/1に学術論文を投稿しました。
  <br />
  規制緩和後のガソリンスタンド数の減少とその要因—閉鎖数·新規参入数および閉鎖要因の変化に焦点を当てて—
    </ProjectCard>
    <ProjectCard title="代表社員桐野が商品開発・管理研究誌にて査読付き研究ノートを発表しました。" link="https://www.jstage.jst.go.jp/article/apdmj/19/1/19_2/_article/-char/ja" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  当機構代表社員桐野が2022/12/15に査読付き研究ノートを発表しました。
  <br />
  時代の流れとモノの需要に関する一考察
    </ProjectCard>
    {
      /*-
      <ProjectCard
       title="Adding a Draft Feature to Gatsby"
       link="https://repository.kulib.kyoto-u.ac.jp/dspace/handle/2433/242453"
       bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
      >
       A solution with the createSchemaCustomization API.
      </ProjectCard>
      <ProjectCard
       title="Theme UI Plugin for Figma"
       link="https://www.lekoarts.de/design/introducing-the-theme-ui-plugin-for-figma?utm_source=cara&utm_medium=Theme"
       bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
      >
       The Theme UI plugin for Figma allows for a workflow where Theme UI is the
       starting point both for design & development.
      </ProjectCard>
      -*/
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      