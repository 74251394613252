import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`京阪マーケティング・リサーチ機構について`}</h2>
    <blockquote>
      <p parentName="blockquote">{`京阪マーケティング・リサーチ機構は、2022/1/31に設立された一般社団法人です。`}</p>
    </blockquote>
    <p>{`なお、一般社団法人及び一般財団法人に関する法律で求められる`}<a parentName="p" {...{
        "href": "/koukoku"
      }}>{`公告事項`}</a>{`は、リンク先をご確認下さい。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      