import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`ご関心を持って頂いた方へ`}</h2>
    <p>{`お問い合わせ事項は、 `}<a parentName="p" {...{
        "href": "https://docs.google.com/forms/u/0/d/1EcS0OrBsZN5npQMRr1ISticDPaGE8p1rsL5UsOxrFws/edit?hl=JA"
      }}>{`Google Form`}</a>{` までお問い合わせ事項をご入力下さい。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      